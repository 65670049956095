<template>
  <v-container
    fluid
    fill-height
    class="d-flex justify-start align-center flex-column pa-0"
  >
    <div>
      <v-row dense>
        <v-col cols="12" class="text-center">
          <!-- <router-link :to="{ name: 'mypage-index' }">
            <img :src="require('@/assets/to_logo.png')" width="320" />
          </router-link> -->
          <migration-logo/>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="d-flex justify-center">
          <v-card
            color="#fff"
            class="py-1 pa-5 mb-3 rounded-xl "
            rounded
            :class="$style.form"
          >
            <v-card-text
              :class="$style.form_text"
              >{{ message }}</v-card-text
            >
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <v-btn
            class="text-center"
            :class="$style.back"
            color="#FD6955"
            text
            @click="goHome"
          >
            HOMEに戻る (Home)
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: ""
    }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    popstate() {
      window.history.pushState(null, null, null);
      return;
    },
    goHome() {
      window.location.href = "https://jam-id.jp";
    }
  },
  created() {},
  mounted() {
    window.addEventListener("popstate", this.popstate);
    this.popstate();
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.popstate);
  }
};
</script>

<style module lang="scss">
.title {
  font-size: 16px;
  color: #6f808c;
  font-weight: 600;
}

.form {
  background-color: #ffffff;
  width: 100%;
  min-width: 320px;
  max-width: 320px;
  padding: 30px 30px;
  margin: 85px 0 15px 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05) !important;
}

.form_text {
  font-size: 13px !important;
  line-height: 1.6;
}

.back {
  font-size: 13px !important;
  font-family: $body-font-family;
}
</style>
